import * as React from "react"

const FormInput = (props) => {
    return (
        <div className={"form-group"}>
            <label>{props.label}</label>
            <input value={props.value} name={props.name} type={props.type ?? "text"} placeholder={props.placeholder ?? ""} onChange={(event) => {
                const target = event.target;
                props.callback(target.name, target.value);
            }}/>
        </div>
    );
}

export default FormInput;
