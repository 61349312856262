import * as React from "react"
import {buildSeo} from "../utils/seo";
import {useEffect, useRef, useState} from "react";
import FormInput from "../components/form/form-input";
import FormTextarea from "../components/form/form-textarea";
import FormSelect from "../components/form/form-select";
import Button from "../components/button";
import FormCheckbox from "../components/form/form-checkbox";
import {Link} from "gatsby";
import {MarqueeTitle} from "../components/cases/marquee-title";
import {Stepper} from "../components/cases/stepper";
//https://formsubmit.co/ajax-documentation
// markup
const ContactPage = () => {
    let [step, setStep] = useState(1);
    let [message, setMessage] = useState(null);
    let [formData, setFormData] = useState({
        "type_of_project": "Un site vitrine 🏢",
        "services_needed": "De tout : du design et du dév 🎁",
        "project_description": "",
        "full_name": "",
        "phone": "",
        "email": "",
        "rgpd": false
    });
    let messageRef = useRef();

    useEffect(() => {
        if(window && window.updateGrid)
            window.updateGrid();
    }, [step]);

    let formUpdateCallback = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });

    }

    let sendForm = () => {
        if(!formData.rgpd) {
            messageRef.current.scrollIntoView({behavior: "smooth"});
            return setMessage("Merci de cocher la case relative à la protection des données avant d'envoyer votre formulaire.");
        }
        fetch("https://formsubmit.co/ajax/devloris@gmail.com", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(
                {
                    ...formData,
                    _subject: "Contact Form"
                }
            )
        })
            .then(response => response.json())
            .then(data => {
                if(data.success) {
                    //setMessage("Le formulaire a été envoyé ! ");
                    setStep(4);
                    setFormData({
                        "type_of_project": "Un site vitrine 🏢",
                        "services_needed": "De tout : du design et du dév 🎁",
                        "project_description": "",
                        "full_name": "",
                        "phone": "",
                        "email": ""
                    });
                }
                else {
                    setMessage("Une erreur s'est produite durant l'envoi du mail ! ");
                }
            })
            .catch(error => console.log(error));
    }

  return (
      <>
          {buildSeo({
              title: 'Contactez-moi',
              description: "Développeur full-stack vivant à Annecy, Loris est diplomé de l'école des Gobelins en Design et Management de l'innovation intéractive."
          })}
          <div className={"page-content contact"}>
              <section className={"container"}>
                  <MarqueeTitle text={"Contact. Contact. Contact. Contact. Contact. Contact."}/>
                  <div className={"page-introduction"}>
                      <div className={"grid cols-2"}>
                          <div className={""}>
                              <h1 className={"h2"}>Dîtes m'en plus sur votre projet !</h1>
                              <p>Vous ne m'avez pas encore parlé de votre projet, qu'il me tient déjà à cœur. Je vous recontacterai au plus vite pour mieux comprendre votre besoin et pour que l'on avance dans la bonne direction</p>
                              <div className={"contact-row"}>
                                  <div className={"contact-row-name"}>mail.</div>
                                  <div className={"contact-row-content"}>
                                      <a href={"mailto:devloris@gmail.com"}>devloris@gmail.com</a>
                                  </div>
                              </div>
                              <div className={"contact-row"}>
                                  <div className={"contact-row-name"}>rdv visio.</div>
                                  <div className={"contact-row-content"}>
                                      <a href={"https://cal.com/lorispinna/discussions-projets?duration=30"}>cal.com/lorispinna/</a>
                                  </div>
                              </div>
                              <div className={"btn-list"}>
                                  <a className={"btn-circle btn-secondary btn-icon btn-linkedin"} aria-label="Twitter" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/lorispinna/"> </a>
                                  <a className={"btn-circle btn-secondary btn-icon btn-github"} aria-label="Github" target="_blank" rel="noreferrer" href="https://github.com/DevLoris/"> </a>
                                  <a className={"btn-circle btn-secondary btn-icon btn-instagram"} aria-label="Instagram" target="_blank" rel="noreferrer" href="https://instagram.com/epicloris"> </a>
                                  <a className={"btn-circle btn-secondary btn-icon btn-twitter"} aria-label="Twitter" target="_blank" rel="noreferrer" href="https://twitter.com/EpicLoris"> </a>
                              </div>
                          </div>
                          <div className={""}>
                              <div className={"bg-blue text-white p-6 md:p-8 shadow"}>
                                  {
                                      (step <= 3) && (
                                          <Stepper max={3} current={step} onClick={(i) => {
                                              if(i <= step)
                                                  setStep(i);
                                          }}/>
                                      )
                                  }
                                  <div ref={messageRef}>
                                      {message && <p className={"callback-message"} onClick={() => {setMessage(null)}}>{message}</p>}
                                  </div>
                                  <form>
                                      {
                                          step === 1 && (
                                              <div className={"step"} >
                                                  <h3>Décrivez votre besoin</h3>
                                                  <FormSelect
                                                      name={"services_needed"}
                                                      label={"D'abord, dites-moi, de quoi avez-vous besoin ?"}
                                                      options={[
                                                          'De tout : du design et du dév 🎁',
                                                          'De design 🎨',
                                                          'De développement ⌨️',
                                                          'D\'informations 📰',
                                                          'D\'autre chose 🤔'
                                                      ]}
                                                      value={formData.services_needed}
                                                      callback={formUpdateCallback}
                                                  />
                                                  {![ 'D\'informations 📰', 'D\'autre chose 🤔'].includes(formData.services_needed) && (
                                                      <>
                                                          <FormSelect
                                                              name={"type_of_project"}
                                                              label={"Pouvez-vous me dire le type de projet ?"}
                                                              options={[ 'Un site vitrine 🏢', 'Un e-commerce 🛒', 'Une web-app 💻', 'Une refonte 🛠️', 'Autre chose 🤔']}
                                                              value={formData.type_of_project}
                                                              callback={formUpdateCallback}
                                                          />
                                                      </>
                                                  )}
                                                  <Button name={"Continuer →"} callback={() => setStep(2)}/>
                                              </div>
                                          )
                                      }
                                      {
                                          step === 2 && (
                                              <div className={"step"} >
                                                  <h3>Un peu de détails</h3>
                                                  {![ 'D\'informations 📰', 'D\'autre chose 🤔'].includes(formData.services_needed) && (
                                                      <FormTextarea
                                                          name={"project_description"}
                                                          value={formData.project_description}
                                                          label={"Parlez-moi de votre besoin, ou de votre projet, etc."}
                                                          placeholder={"Je voudrais faire un site e-commerce pour vendre des ananas..."}
                                                          callback={formUpdateCallback}
                                                      />
                                                  )}

                                                  {[ 'D\'informations 📰', 'D\'autre chose 🤔'].includes(formData.services_needed) && (
                                                      <FormTextarea
                                                          name={"project_description"}
                                                          value={formData.project_description}
                                                          label={"Quelle est votre demande ?"}
                                                          placeholder={"J'ai besoin de vous pour assurer un cours incroyable !"}
                                                          callback={formUpdateCallback}
                                                      />
                                                  )}
                                                  <Button name={"Continuer →"} callback={() => setStep(3)}/>
                                              </div>
                                          )
                                      }
                                      {
                                          step === 3 && (
                                              <div className={"step"} >
                                                  <h3>Qui êtes-vous ?</h3>
                                                  <FormInput
                                                      name={"full_name"}
                                                      value={formData.full_name}
                                                      label={"Votre nom ou votre société"}
                                                      placeholder={"Martin Dupont"}
                                                      callback={formUpdateCallback}
                                                  />
                                                  <div className={"grid cols-2"}>
                                                      <FormInput
                                                          name={"phone"}
                                                          value={formData.phone}
                                                          label={"Votre numéro"}
                                                          placeholder={"+330612345678"}
                                                          type={"phone"}
                                                          callback={formUpdateCallback}
                                                      />
                                                      <FormInput
                                                          name={"email"}
                                                          value={formData.email}
                                                          label={"Votre adresse mail"}
                                                          placeholder={"yourmail@example.com"}
                                                          type={"email"}
                                                          callback={formUpdateCallback}
                                                      />
                                                  </div>
                                                  <FormCheckbox callback={formUpdateCallback} name={"rgpd"}>
                                                      En cochant cette case, vous acceptez que les informations saisies dans ce formulaire soient utilisées dans le cadre de la demande de contact et de la relation commerciale qui peut en découler. Pour connaître et exercer vos droits, veuillez consulter <Link to={"/mentions-legales/"} target={"_blank"}>mes mentions légales</Link>.
                                                  </FormCheckbox>
                                                  <Button name={"Discutons !"} callback={sendForm}/>
                                              </div>
                                          )
                                      }
                                      {
                                          step === 4 && (
                                              <div className={"final-step"} >
                                                  <img alt="Sticker" className="sticker sticker_Letter"
                                                       src="/images/stickers/Letter.svg"/>
                                                  <h3>C'est dans la boite !</h3>
                                                  <p>C'est bon, le mail a bien été envoyé ! Je vous répondrais rapidement, par e-mail, pour convenir d'un rendez-vous ensemble !</p>
                                              </div>
                                          )
                                      }
                                  </form>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          </div>
      </>
  )
}

export default ContactPage
