import * as React from "react"
import {useState} from "react";

const FormSelect = (props) => {
    let [value, setValue] = useState(props.value);
    let [toggle, setToggle] = useState(false);

    return (
        <div className={"form-group"}>
            <label>{props.label}</label>
            <div className={"custom-select"} role={"button"} tabIndex={0}  onMouseLeave={() => setToggle(false)}>
                <div  role={"button"} tabIndex={0} className={"custom-selected"} onClick={() => setToggle(!toggle)} onKeyDown={(e) => { if(e.key === "Space") { setToggle(!toggle); e.preventDefault() } }}>
                    {value}
                </div>
                {toggle ? (
                    <div className={"custom-options"}>
                        {props.options.map((v, k) => (
                            <div role={"button"} key={v} tabIndex={k + 1} onKeyDown={(e) => { if(e.key === "Space") {
                                setValue(v);
                                setToggle(false);
                                props.callback(props.name, v);
                                e.preventDefault();
                            } }} onClick={() => {
                                setValue(v);
                                setToggle(false);
                                props.callback(props.name, v);
                            }} className={"custom-option"}>{v}</div>
                        ))}
                    </div>
                ): (<></>)}
            </div>
        </div>
    );
}

export default FormSelect;
