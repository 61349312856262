import * as React from "react"
import {useState} from "react";

const FormCheckbox = (props) => {
    let [value, setValue] = useState(props.value);

    return (
        <div className={"form-group"}>
            <div role={"button"} tabIndex={0} className={"custom-checkbox"} onKeyDown={(e) => { if(e.key === "Space") {
                props.callback(props.name, !value);
                setValue(!value);
                e.preventDefault();
            } }}  onClick={() => {
                props.callback(props.name, !value);
                setValue(!value);
            }}>
                <div className={value ? "checked": ""}></div>
                <label>{props.children}</label>
            </div>
        </div>
    );
}

export default FormCheckbox;
