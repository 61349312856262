import * as React from "react";

export const Stepper = ({max, current, onClick}) => {
    return (
        <div className={"stepper"}>
            {Array(max).fill(0).map((v, i) => {
                return (
                    <div role={"button"} tabIndex={i} onKeyDown={() => {onClick(i + 1)}} onClick={() => {onClick(i + 1)}} className={`step ${(i + 1 === current) ? 'active' : ''} `} key={i}>{i + 1}</div>
                )
            })}
        </div>
    )
}
