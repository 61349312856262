import * as React from "react"

const FormTextarea = (props) => {
    return (
        <div className={"form-group"}>
            <label>{props.label}</label>
            <textarea defaultValue={props.value} name={props.name}  placeholder={props.placeholder ?? ""} onChange={(event) => {
                const target = event.target;
                props.callback(target.name, target.value);
            }}></textarea>
        </div>
    );
}

export default FormTextarea;
